const users = {
    page_title: 'Gestion des utilisateurs',
    title: 'Gestion des utilisateurs',
    subtitle: "Gérer les identités des utilisateurs, y compris la création d'utilisateurs, la modification des informations sur les utilisateurs, la consultation des journaux des utilisateurs, la réinitialisation des mots de passe et la suppression des utilisateurs.",
    create: 'Ajouter un utilisateur',
    create_subtitle: "Fournir au moins l'un des champs suivants pour continuer.",
    error_missing_identifier: 'Vous devez saisir au moins un identifiant pour créer un utilisateur.',
    user_name: 'Utilisateur',
    application_name: "De l'application",
    latest_sign_in: 'Dernière connexion',
    create_form_username: "Nom d'utilisateur",
    create_form_password: 'Mot de passe',
    create_form_name: 'Nom complet',
    placeholder_name: 'Monsieur/Madame Dupont',
    placeholder_email: 'mdupont@example.com',
    placeholder_username: 'user123',
    placeholder_phone: '+33 1 23 45 67 89',
    unnamed: 'Sans nom',
    search: "Rechercher par nom, email, téléphone ou nom d'utilisateur",
    check_user_detail: "Vérifier les détails de l'utilisateur",
    placeholder_title: 'Gestion des utilisateurs',
    placeholder_description: "Chaque utilisateur a un profil contenant toutes les informations sur l'utilisateur. Il est composé de données de base, d'identités sociales et de données personnalisées.",
};
export default Object.freeze(users);
