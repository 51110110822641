const invitation = {
    find_your_tenants: 'Trouvez vos locataires',
    find_tenants_description: 'Votre adresse e-mail peut déjà être enregistrée avec plusieurs locataires. Vous pouvez choisir de rejoindre les existants ou continuer à créer un nouveau.',
    create_new_tenant: 'Créer un nouveau locataire',
    email_not_match_title: 'Vous êtes actuellement connecté en tant que\n{{email}}',
    email_not_match_description: "Veuillez vous connecter avec le compte correct pour accepter l'invitation et devenir un membre de l'organisation.",
    switch_account: 'Se connecter à un autre compte',
    invalid_invitation_status: "Invitation invalide. Veuillez contacter l'administrateur et réessayer.",
    invitation_not_found: "Invitation non trouvée. Veuillez contacter l'administrateur.",
};
export default Object.freeze(invitation);
