const general = {
    placeholder: 'Emplacement',
    submit: 'Soumettre',
    skip: 'Passer',
    next: 'Suivant',
    back: 'Retourner',
    retry: 'Essayez à nouveau',
    done: 'Terminé',
    search: 'Recherche',
    search_placeholder: 'Recherche...',
    clear_result: 'Effacer les résultats',
    save: 'Sauvegarder',
    save_changes: 'Sauvegarder les modifications',
    saved: 'Sauvegardé',
    discard: 'Abandonner',
    loading: 'Chargement...',
    redirecting: 'Redirection...',
    add: 'Ajouter',
    added: 'Ajouté',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    check_out: 'Vérifier',
    create: 'Créer',
    set_up: 'Configurer',
    customize: 'Personnaliser',
    enable: 'Activer',
    reminder: 'Rappel',
    edit: 'Éditer',
    delete: 'Supprimer',
    deleted: 'Supprimé',
    more_options: "PLUS D'OPTIONS",
    close: 'Fermer',
    copy: 'Copier',
    copying: 'Copie',
    copied: 'Copié',
    required: 'Requis',
    add_another: 'Ajouter un autre',
    create_another: 'Créer un autre',
    deletion_confirmation: 'Êtes-vous sûr de vouloir supprimer ce {{title}} ?',
    settings_nav: 'Paramètres',
    unsaved_changes_warning: 'Vous avez effectué des changements. Êtes-vous sûr de vouloir quitter cette page ?',
    leave_page: 'Quittez la page',
    stay_on_page: 'Rester sur la page',
    type_to_search: 'Tapez pour rechercher',
    got_it: 'Compris.',
    continue: 'Continuez',
    page_info: '{{min, number}}-{{max, number}} de {{total, number}}',
    learn_more: 'En savoir plus',
    /** UNTRANSLATED */
    tab_error_one: '{{count, number}} error',
    tab_error_other: '{{count, number}} erreurs',
    skip_for_now: 'Passer pour l`instant',
    remove: 'Supprimer',
    visit: 'Visiter',
    join: 'Rejoindre',
    try_now: 'Essayez maintenant',
    multiple_form_field: '(Multiple)',
    demo: 'Démo',
    unnamed: 'Sans nom',
    view: 'Vue',
    open: 'Ouvrir',
    hide: 'Cacher',
    unknown_error: 'Erreur inconnue, veuillez réessayer ultérieurement.',
    select: 'Sélectionner',
    contact_us_action: 'Nous contacter',
    description: 'Description',
    name: 'Nom',
    add_field: 'Ajouter {{field}}',
    create_field: 'Créer {{field}}',
    edit_field: 'Modifier {{field}}',
    delete_field: 'Supprimer {{field}}',
    coming_soon: 'Bientôt disponible',
    or: 'Ou',
};
export default Object.freeze(general);
