const log_details = {
    page_title: "Détails du journal d'audit",
    back_to_logs: "Retour aux journaux d'audit",
    back_to: 'Retour à {{name}}',
    success: 'Succès',
    failed: 'Échoué',
    event_key: "Clé d'événement",
    application: 'Application',
    ip_address: 'Addresse IP',
    user: 'Utilisateur',
    log_id: 'ID du journal',
    time: 'Temps',
    user_agent: "Agent de l'utilisateur",
    tab_details: 'Détails',
    raw_data: 'Données brutes',
};
export default Object.freeze(log_details);
